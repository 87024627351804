@import './src/scss/variables';
@import './src/scss/game';

$width: 30px;
$height: 30px;

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

.game__scrollable {
	width: 100%;
	overflow-x: auto;
  padding: 0;
}

.mine__table {
  width: 100%;
  display: flex;

  &-row {
    display: flex;

    &:first-of-type {
      //border-top: 1px solid;
    }
  }

  &-content {
    background: $color2;
    border: 1px solid;
    margin: auto;
  }

  .fa-light {
    font-weight: 400 !important;
	}
}

.mine__table-row:last-of-type .mine__cell_opened,
.mine__table-row:last-of-type .mine__cell_closed {
	border-bottom: 0;
}

.mine__cell {
  min-width: $width;
  width: $width;
  height: $height;
  color: $color;
  font-weight: 600;
  border-style: outset;
  text-align: center;
  vertical-align: middle;

  &:first-of-type {
    //border-left: 1px solid;
  }

  &_closed {
    background-color: $color6;
    height: 100%;
    width: 100%;
    display: table;
    border-bottom: 1px solid;
    border-right: 1px solid;
  }

  &_opened {
    border-right: 1px solid;
    border-bottom: 1px solid;
    background: $color2;
    width: 100%;
    height: 100%;
  }
}

.mine__cell:last-of-type .mine__cell_closed,
.mine__cell:last-of-type .mine__cell_opened {
	border-right: 0;
}

.mine__fire {
  color: $color11;
  display: table-cell;
  vertical-align: middle;
  width: 30px;
  height: 30px;
  font-size: $font-size;

  &.mine__resolve {
    color: $color9;
  }

  &.mine__blowup {
    //animation: pulse-animation 1s linear infinite;
    color: $color;
  }
}

.mine__flag:after,
.mine__hint_flag:after {
  content: "\f1e2";
  display: table-cell;
  vertical-align: middle;
  color: $color11;
  font-size: $font-size;
}

.mine__hint_cell:after {
  content: "\f3c2";
  display: table-cell;
  vertical-align: middle;
  font-size: $font-size;
}

.mine__hint_flag:after,
.mine__hint_cell:after {
  color: $color9;
  //animation: blink-animation 1s steps(5, start) infinite;
}

.mine__disarm:after {
  content: "\f1e2";
  font-size: $font-size;
  color: $color7;
  display: table-cell;
  vertical-align: middle;
  //animation: blink-animation 1s steps(5, start) infinite;
}

.mine__question:after {
  content: "\f128";
  font-size: $font-size;
  display: table-cell;
  vertical-align: middle;
  color: $color7;
}

.mine {
  height: 100%;
  font-size: 1.1em;
  line-height: $height;
  //font-weight: 600;
  position: relative;

  .miss {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: $color2;
    color: $color;
  }

  &__1 {
    color: $color14;
  }

  &__2 {
    color: $color9;
  }

  &__3 {
    color: $color11;
  }

  &__4 {
    color: $color7;
  }

  &__5 {
    color: $color13;
  }

  &__6 {
    color: $color15;
  }

  &__7 {
    color: $color10;
  }

  &__8 {
    color: $color10;
  }
}

.modal__content-custom {
	display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;

  &_group {
    display: flex;
    align-items: center;
    gap: .5rem;
  }

  &_input {
    font-family: $font-family;
    font-size: $font-size;
    border: 0;
    text-align: center;
    color: $color;
  }
}

@import 'dark-theme';
//@import './loading';